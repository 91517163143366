.container {
  display: flex;
  flex-direction: column;
  gap: 41px;
}

.header {
  display: flex;
  gap: 27px;
}

.header > img {
  width: 91px;
  height: 91px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 999px;
}

.header > p {
  font-weight: 500;
  font-size: 19px;
  color: #000000;
}

.title {
  font-weight: 500;
  font-size: 19px;
  color: #000000;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableHeader {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15% 15% 10%;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 12px 16px;
  align-items: center;
  /* gap: 30px; */
}

.tableHeader > p {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: 40vh;
}
