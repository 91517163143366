.countAndSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.count {
  display: flex;
  justify-content: space-between;
  padding: 14px 0px 8px;
  gap: 12px;
  margin-bottom: 12px;
}

.table {
}

.filters {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 15% 20% 20% 20% 10% 15%;
}

.tableHeader {
  padding: 12px 16px;
  background-color: #fafafa;
  display: grid;
  border-radius: 4px;
  grid-template-columns: 15% 20% 20% 20% 10% 15%;
}

.tableHeader > p {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.tableBody {
  padding: 12px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: 50vh;
}
