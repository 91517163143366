.container {
    position: absolute;
    width: 30%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.formContainer label {
    font-weight: 500;
    font-size: 16px !important;
}

.title {
    margin-bottom: 15% !important;
    font-weight: 800 !important;
}

.button {
    margin-top: 4%;
}
