.campaign {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 15% 20% 15% 15% 20% 15%;
  /* gap: 30px; */
}
.campaign > p {
  cursor: pointer;
}

.timeStamp {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.campaignName {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.brandName {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.platform {
  display: flex;
  gap: 7px;
  align-items: flex-start;
}

.platform > img {
  width: 17px;
  height: 19px;
}

.platform > div {
  display: flex;
  flex-direction: column;
}

.platform > div > p {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.platformStatus {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.totalPrice {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.prices {
  display: flex;
  flex-direction: column;
}

.price {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.011em;

  color: rgba(0, 0, 0, 0.4);
}

.viewRemarks {
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #2130ff;
}
