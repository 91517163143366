@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Roboto", sans-serif !important;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.15);
	border-radius: 20px;
}

p {
	margin: 0 !important;
}

.ant-drawer {
	z-index: 999 !important;
}

.ant-collapse-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
