.container {
  display: flex;
  flex-direction: column;
  gap: 41px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.headerLeft {
  display: flex;
  flex-direction: column;
}

.headerRight {
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #2130ff;
  cursor: pointer;
}

.campaignName {
  font-weight: 600;
  font-size: 16px;
  color: #09121f;
}

.brandName {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.options {
  display: flex;
  gap: 26px;
}

.option {
  padding: 8px 7px;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  cursor: pointer;
}

.active {
  padding: 8px 7px;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  background: #d9d9d9;
  cursor: pointer;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paymentTableHeader {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 12px 16px;
  align-items: center;
  /* gap: 30px; */
}

.paymentTableHeader > p {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: 40vh;
}

.campaignName {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.platformImg {
  width: 18px;
  height: 19px;
  margin-left: 6px;
}

.brandName {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.platform {
  display: flex;
  gap: 7px;
  align-items: flex-start;
}

.platform > img {
  width: 17px;
  height: 19px;
}

.platform > div {
  display: flex;
  flex-direction: column;
}

.platform > div > p {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.platformStatus {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.totalPrice {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.prices {
  display: flex;
  flex-direction: column;
}

.price {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.011em;

  color: rgba(0, 0, 0, 0.4);
}

.viewRemarks {
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #2130ff;
}

.profile {
  width: 40px;
  height: 40px;
  border-radius: 999px;
}

.row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-block: 20;
}
