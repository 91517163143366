.extra {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.viewCampaign {
  text-decoration: underline;
  color: #2130ff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
}

.logo {
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.personalDetails {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 26px;
}

.businessDetails {
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  gap: 49px;
}

.info {
  display: flex;
  /* align-items: center; */
}
.secondaryInfo {
  display: flex;
  /* align-items: center; */
  /* gap: 31px; */
}

.label {
  flex: 0.3;
  font-size: 15px;
  font-weight: 500;

  color: rgba(0, 0, 0, 0.4);
}

.secondaryLabel {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  flex: 0.25;
}

.secondaryValue {
  flex: 0.75;
}

.value {
  flex: 0.7;
}

.communities {
  display: flex;

  gap: 31px;
}

.community {
  display: flex;
  align-items: center;
  gap: 21px;

  flex-wrap: wrap;
}

.community > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background: #f0eff8;
  border-radius: 32px;
  text-align: center;
}

.docUrl {
  font-weight: 500;
  text-decoration-line: underline;
  flex: 0.75;
  color: #2130ff;
}

.gst {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  flex: 0.75;
}

.gstValue {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.gstValue > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 115%;
  color: rgba(0, 0, 0, 0.4);
}

.gstInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}

.subText {
  font-weight: 400;
  font-size: 14px;

  color: #999999;
}
