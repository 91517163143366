.payment {
  padding: 12px 16px;
  display: grid;
  width: 100%;
  grid-template-columns: 15% 15% 15% 15% 10% 20% 10%;
  /* gap: 30px; */
}

.timeStamp {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.manualPaymentText {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
}
