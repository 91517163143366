.tile {
  padding: 9px 8px;
  display: grid;
  grid-template-columns: 2% 5% 20% 20% 20% 10% 15% 8%;
  cursor: pointer;
}

.logo {
  width: 30px;
  height: 30px;
  border-radius: 999px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
