.title {
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 0px !important;
}

.listHeader {
  background: #f5f5f5;
  box-shadow: inset 1px 0px 0px #d9d9d9, inset -1px 0px 0px #d9d9d9,
    inset 0px 1px 0px #d9d9d9, inset 0px -1px 0px #d9d9d9;
  border-radius: 10px;
  padding: 16px 10px 16px 44px;
  /* display: grid; */
  /* grid-template-columns: 25% 25% 25% 25%; */
}

.headerItem {
  /* text-align: center; */
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* font-family: 'Roboto'; */
}

.profilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.column {
  align-items: center;
  display: inline-flex !important;
  justify-content: center;
}

.csvInput {
  padding: 10px;
  display: block;
  margin: 15px auto;

  border-radius: 5px;
}

.content {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #595959 !important;
}

.fullName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  width: 120px;
  text-align: left;
  word-wrap: break-word;
}
