.logo {
  height: 32px;
  margin: 16px;
  /* margin-bottom: 26%; */
  text-align: center;
}

.layout {
  margin-left: 200px;
  background-color: white !important;
  padding: 40px;
  overflow: scroll;
}

.header {
  padding-left: 40px;
  font-size: 32px;
  font-weight: 500;
}

.signout {
  color: white;
  text-align: center;
  cursor: pointer;
}

.navOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 16px;
  padding: 5px 0px 5px 30px;
  cursor: pointer;
}

.optionActive {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 16px;
  background-color: #919191;
  padding: 5px 0px 5px 30px;
  cursor: pointer;
}
