.container {
  display: grid;
  gap: 20px;
}

.pX20 {
  padding: 0 20px;
}

.inputContainer {
  display: flex !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.optionsContainer {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 20px;
}

.g10 {
  display: grid;
  gap: 10px;
}

.tableHeader {
  border-radius: 10px;
  background: rgba(235, 63, 121, 0.05);
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 20% 15% 15% 10% 40%;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 600;
}

.profilePictureContainer {
  position: relative;
}

.profile {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.lockContainer {
  position: absolute;
  bottom: 2px;
  right: -6px;
  padding: 4px;
  border-radius: 18px;
  border: 0.5px solid rgba(83, 58, 245, 0.1);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(83, 58, 245, 0.1);
  display: flex;
  flex-shrink: 0;
}

.name {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
}

.tableItemContainer {
  padding: 20px;
  display: grid;
  grid-template-columns: 20% 15% 15% 10% 40%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
}
